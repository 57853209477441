<!--  -->
<template>
  <div class="upload-main">
    <div class="upload-video" @click="handleClick">
      <el-button :disabled="disabled">
        <i class="el-icon-paperclip"></i>附件</el-button
      >
      <input
        class="upload-video-input"
        @change="inputChange"
        ref="input"
        type="file"
        multiple
      />
    </div>
    <van-popup v-model="showProcess">
      <el-progress type="circle" :percentage="percentage"></el-progress>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  singleFilePartUpload,
  fileUploadExceptHandle,
} from "@/api/email/index.js";
import { guid } from "@/utils";
export default {
  name: "singleFilePartUpload",
  data() {
    return {
      uploader: null,
      showProcess: false,
      chunkSize: 2 * 1024 * 1024,
      files: [],
      progressRate: 0,
      loading: 0, //上传状态：0未上传 1上传中 2取消上传 3上传失败 4成功
      percentage: 0, //进度条
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    // 10m
    maxsize: {
      type: Number,
      default: 1024 * 1024 * 10,
    },
  },

  components: {},

  computed: {
    // listFileProgress: {
    //   get() {
    //     return this.list || [];
    //   },
    //   set(value) {
    //     this.$emit("change", value);
    //   },
    // },
  },

  mounted() {},

  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$refs.input.value = null;
        this.$refs.input.click();
      }
    },
    inputChange() {
      let inputDOM = this.$refs.input;
      let files = inputDOM.files;
      for (let file of files) {
        let item = {
          MD5: guid(32),
          file: file,
          process: [],
          totalChunks: 0, // 计算总共有多少个分片
          currentChunk: 0,
          chunks: [], // 定义一个数组存放所有的分片
        };
        this.files.push(item);
        this.fileChunk(item);
      }
    },
    //文件分片
    fileChunk(file) {
      this.loading = 1; //上传状态：0未上传 1上传中 2取消上传 3上传失败 4成功
      // 计算总共有多少个分片
      let totalChunks = Math.ceil(file.file.size / this.chunkSize);
      file.totalChunks = totalChunks;
      // 遍历所有的分片
      for (var i = 0; i < file.totalChunks; i++) {
        // 利用slice方法获取每个分片
        var start = i * this.chunkSize;
        var end = Math.min(file.file.size, start + this.chunkSize);
        var blob = file.file.slice(start, end);
        // 将每个分片添加到数组中
        file.chunks.push(blob);
        // 定义一个变量存放当前正在上传的分片索引
      }
      this.formatFile(file);
    },
    //格式化文件
    formatFile(file) {
      // 如果当前索引等于总数，则说明所有分片都已经上传完成，请求合并接口即可
      if (file.currentChunk === file.totalChunks) {
        this.$emit("onUploadEnd");
        file.currentChunk = 0;
        this.loading = 4; //上传状态：0未上传 1上传中 2取消上传 3上传失败 4成功
        return;
      } else {
        // this.isUploading//上传状态：0未上传 1上传中 2取消上传 3上传失败 4成功
        if (this.loading === 2) {
          this.cancelFile(file);
          Toast({
            type: "error",
            message: "取消上传",
          });

          return;
        } else if (this.loading === 3) {
          Toast({
            type: "error",
            message: "上传失败,请重新上传",
          });
        } else {
          var chunk = file.chunks[file.currentChunk];
          // 创建一个表单数据对象，添加相关信息（如MD5值、索引、总数等）
          var formData = new FormData();
          formData.append("filePart", chunk); //filePart	当前文件片
          formData.append("partNum", file.totalChunks); //partNum	文件分片总数
          formData.append("partIndex", file.currentChunk); //partIndex	当前分片定位
          formData.append("fileUid", file.MD5); //当前文件uuid
          formData.append("fileName", file.file.name); //单次分片的文件名称
          //formData.append("type", null); //type	类型1、图片；2、视频
          this.asyncUploadFile(file, formData);
        }
      }
    },
    // 上传文件
    asyncUploadFile(file, formData) {
      this.showProcess = true;
      singleFilePartUpload(formData)
        .then((res) => {
          let { code } = res;
          const percentage = parseFloat(
            (((file.currentChunk + 1) / file.totalChunks) * 100).toFixed(0)
          );
          this.percentage = percentage;
          if (res.data) {
            const obj = {
              code: res.data.fileCode,
              name: res.data.name,
              url: res.data.fileUrl,
            };
            this.showProcess = false;
            this.$emit("updateFileList", obj);
          }
          if (code == 200) {
            if (file.currentChunk >= file.totalChunks) {
              return;
            } else {
              if (file.currentChunk === file.totalChunks) {
                Toast({
                  type: "success",
                  message: "上传成功",
                });
                this.isUploading = 4; //上传状态：0未上传 1上传中 2取消上传 3上传失败 4成功
              } else {
                file.currentChunk++;
                this.formatFile(file);
              }
            }
          } else {
            this.showProcess = false;
            this.loading = 3; //上传状态：0未上传 1上传中 2取消上传 3上传失败 4成功
            Toast({
              type: "error",
              message: "上传失败,请重新上传",
            });
            return;
          }
        })
        .catch((e) => {
          this.cancelFile(file);
          this.showProcess = false;
          return;
        });
    },
    // 取消上传
    cancelFile(file) {
      fileUploadExceptHandle({ fileUid: file.MD5 })
        .then((res) => {})
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-main {
  display: inline-block;
}
.upload-video {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: none;
}
.upload-video-input {
  display: none;
}
</style>
