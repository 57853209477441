<!-- 写邮件 -->
<template>
  <div class="send-email-page">
    <div class="rule-form">
      <van-cell class="title-box">
        <p class="title">编辑草稿</p>
      </van-cell>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="1.5rem"
        class="form"
      >
        <el-form-item label="收件人：" prop="recieveEmails">
          <el-select
            v-model="ruleForm.recieveEmails"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder=""
          >
            <el-option
              v-for="item in allAddress"
              :key="item.value"
              :label="item.label + '<' + item.value + '>'"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="抄送人：" prop="ccEmails">
          <el-select
            v-model="ruleForm.ccEmails"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder=""
          >
            <el-option
              v-for="item in allAddress"
              :key="item.value"
              :label="item.label + '<' + item.value + '>'"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发件人：" v-if="myEmailInfo">
          <div>
            <span class="name">{{ myEmailInfo.staffName }} </span>
            <span class="link">&lt;{{ myEmailInfo.email }}&gt;</span>
          </div>
        </el-form-item>
        <el-form-item label="主题：" prop="subject">
          <el-input
            v-model.trim="ruleForm.subject"
            maxlength="250"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
        <el-form-item label="正文：" prop="content" style="height: auto">
          <quillEditor
            v-model="ruleForm.content"
            maxlength="8000"
            :message="ruleForm.content"
            :readonly="false"
            @content="getContent"
          ></quillEditor>
        </el-form-item>
        <el-form-item label="附件：">
          <div class="upload-btns">
            <singleFilePartUpload
              @updateFileList="updateFileList"
            ></singleFilePartUpload>
          </div>
        </el-form-item>
        <el-form-item v-if="fileCardList.length > 0">
          <div class="upload-list">
            <div
              class="upload-list-item"
              v-for="(file, f) in fileCardList"
              :key="f"
            >
              <a :href="file.url" target="_blank" class="upload-list-item-name"
                ><i class="el-icon-document"></i> {{ file.name }}
              </a>

              <i
                class="el-icon-close icon-1"
                title="删除"
                @click="handleRemoveFileCard(file, f)"
              ></i>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer-buttons">
      <van-button :loading="loading" class="pass" @click="validKeep"
        >发送</van-button
      >
      <van-button
        :loading="loading"
        class="set-time-btn"
        @click="validSetTimeKeep"
        >定时发送</van-button
      >
      <van-button class="reject-btn" :loading="loading" @click="KeepDraft"
        >保存为草稿</van-button
      >
    </div>
    <el-dialog
      title="请选择您想定时发送的时间"
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" class="send-form" label-width="100px">
        <el-form-item label="发送时间">
          <el-date-picker
            v-model="ruleForm.sendTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择发送时间"
            :picker-options="pickerOptions"
            popper-class="popperClass1"
          >
          </el-date-picker
        ></el-form-item>
        <el-form-item label=" ">
          <p class="remark" v-if="ruleForm.sendTime">
            本邮件将于<span class="link">{{ ruleForm.sendTime }}</span
            >投递到对方邮箱
          </p>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" type="primary" @click="handleSetTime"
          >确 定</el-button
        >
        <el-button :loading="loading" @click="closeDis">取消定时发送</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { sendEmail, emailInfo } from "@/api/email/index.js";
import { mapActions, mapState } from "vuex";
import singleFilePartUpload from "./components/upload/index.vue";
import { Toast } from "vant";
import quillEditor from "@/components/quillEditor/index.vue";


export default {
  data() {
    return {
      detailInfo: null,
      ruleForm: {
        attachments: [],
        ccEmails: [],
        content: "",
        recieveEmails: [],
        sendStatus: 1, //发送状态0-草稿1-立即发送2-定时发布
        sendTime: "",
        subject: "",
        unid: "",
      },
      rules: {
        recieveEmails: [
          { required: true, message: "收件人不能为空", trigger: "change" },
        ],
        subject: [{ required: true, message: "主题不能为空", trigger: "blur" }],
      },
      dialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return (
            new Date(time).getTime() < new Date().getTime() - 3600 * 1000 * 24
          );
        },
      },
      content: "",
      fileCardList: [],
      listFileProgres: [],
      loading: false,
    };
  },

  components: { singleFilePartUpload, quillEditor },

  computed: {
    ...mapState("email", ["myEmailInfo", "allAddress"]),
    unid() {
      return this.$route.query.unid;
    },
    isSender() {
      return this.$route.query.isSender || "";
    },
  },

  mounted() {
    this.getEmailInfo().then((res) => {
      if (this.myEmailInfo) {
        this.getAllUsers();
        this.getEmail();
      }
    });
  },

  methods: {
    ...mapActions("email", ["getEmailInfo", "getAllUsers"]),

    getEmail() {
      emailInfo({ unid: this.unid, isSender: this.isSender })
        .then((res) => {
          let { data, code } = res;
          if (code == 200) {
            this.detailInfo = data;
            this.initData();
          }
        })
        .catch((err) => {});
    },
    initData() {
      this.ruleForm.subject = `${this.detailInfo.subject}`;
      if (this.detailInfo.attachments) {
        this.fileCardList = this.detailInfo.attachments.map((e) => {
          return {
            code: e.fileCode,
            ...e,
          };
        });
      }
      this.ruleForm.content = this.detailInfo.content;

      if (this.detailInfo.recieves) {
        this.ruleForm.recieveEmails = this.detailInfo.recieves.map((e) => {
          return e.email;
        });
      }
      if (this.detailInfo.ccvos) {
        this.ruleForm.ccEmails = this.detailInfo.ccvos.map((e) => {
          return e.email;
        });
      }
    },
    getContent(content) {
      this.ruleForm.content = content;
    },
    destroyHrUploadContain() {
      this.listFileProgres = [];
    },
    updateFileList(file) {
      this.fileCardList.push(file);
    },
    handleRemoveFileCard(file, i) {
      this.fileCardList.splice(i, 1);
    },
    onFailed() {},
    validKeep() {
      this.ruleForm.attachments = this.fileCardList.map((e) => {
        return e.code;
      });
      const params = {
        ...this.ruleForm,
        sendStatus: 1, //发送状态0-草稿1-立即发送2-定时发布
        unid: this.detailInfo.unid,
      };
      this.loading = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          sendEmail(params)
            .then((res) => {
              if (res) {
                let { code, msg } = res;
                if (code == 200) {
                  Toast(msg);
                  this.$router.push(`/EmailOutbox`);
                }
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
    validSetTimeKeep() {
      this.ruleForm.attachments = this.fileCardList.map((e) => {
        return e.code;
      });
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.dialogVisible = true;
        }
      });
    },
    KeepDraft() {
      this.ruleForm.attachments = this.fileCardList.map((e) => {
        return e.code;
      });
      const params = {
        ...this.ruleForm,
        sendStatus: 0, //发送状态0-草稿1-立即发送2-定时发布
        unid: this.detailInfo.unid,
      };
      this.loading = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          sendEmail(params)
            .then((res) => {
              if (res) {
                let { code, msg } = res;
                if (code == 200) {
                  Toast(msg);
                  this.$router.go(-1);
                }
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
    handleSetTime() {
      const params = {
        ...this.ruleForm,
        sendStatus: 2,
        unid: this.detailInfo.unid,
      };
      this.loading = true;
      sendEmail(params)
        .then((res) => {
          if (res) {
            let { code, msg } = res;
            if (code == 200) {
              this.dialogVisible = false;
              this.$router.replace("/EmailOutbox");
              Toast(msg);
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.dialogVisible = false;
          this.loading = false;
        });
    },
    handleClose(done) {
      this.ruleForm.sendTime = "";
      this.loading = false;
      done();
    },
    closeDis() {
      this.ruleForm.sendTime = "";
      this.dialogVisible = false;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.send-email-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .rule-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    .title-box {
      background: #f0f6ff;
      font-weight: 900;
      height: 0.8rem;
      .title {
        text-align: center;
      }
    }
    .form {
      flex: 1;
      overflow-y: auto;
      padding: 0.2rem;
      // .title-popup {
      //   font-weight: 700;
      //   font-size: 0.28rem;
      //   line-height: 1rem;
      //   /* height: 0.48rem; */
      //   padding: 0 0.3rem;
      //   letter-spacing: 0.01rem;
      // }
      // .text-cell {
      //   min-height: 5rem;
      // }
      .el-select {
        width: 100%;
        .el-input {
          width: 100%;
        }
      }
      .cell-upload {
        display: flex;
        flex-direction: row;
      }
      .upload-list {
        margin: 0;
        padding: 0;
        width: 5.5rem;
        max-width: 5.5rem;
        padding: 2px;
        border-radius: 4px;
        .upload-list-item {
          // max-width: 400px;
          width: 5.5rem;
          display: flex;
          flex-direction: row;
          font-size: 12px;
          // color: #999;
          align-items: center;
          .upload-list-item-name {
            width: 5.2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 5px;
          }

          .icon-1 {
            display: inline;
          }
        }
        // .upload-list-item:hover {
        //   background: #eee;
        //   .icon-0 {
        //     display: none;
        //     color: #67c23a;
        //   }
        //   .icon-1 {
        //     display: inline;
        //   }
        // }
      }
    }
  }
  .footer-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right: 0.3rem;
    width: 7.5rem;
    height: 1.04rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    .pass {
      width: 2.18rem;
      height: 0.8rem;
      background: #0094ee;
      border-radius: 0.4rem 0 0 0.4rem;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
    }
    .set-time-btn {
      width: 2.18rem;
      height: 0.8rem;
      background: #e5f4fd;

      font-size: 0.26rem;

      line-height: 0.37rem;
      font-size: 0.26rem;
      color: #0094ee;
      line-height: 0.37rem;
    }
    .reject-btn {
      width: 2.18rem;
      height: 0.8rem;
      background: #0094ee;
      border-radius: 0 0.4rem 0.4rem 0;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
      font-size: 0.26rem;

      line-height: 0.37rem;
    }
  }
  .send-form {
    .el-input {
      width: 80%;
    }
  }
  .link {
    color: #0094ee;
  }
}
</style>
<style>
.el-select-dropdown {
  max-width: 5.2rem;
  overflow: hidden;
}
.el-select-dropdown__item {
  display: inline-block;
  overflow: hidden;
  max-width: 5.2rem;
}
.el-select-dropdown__item span {
  min-width: 5rem;
  padding: 0.05rem;
  display: inline-block;
  overflow: hidden;
}
.popperClass1 .el-time-panel {
  max-width: 2.7rem !important;
  min-width: 2rem;
}
.popperClass1 .el-time-panel__footer {
  text-align: center;
}
</style>
